import React from 'react'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFormViewAsyn, formDetailsAsync, updateViewFormAsyn, setviewForms, setStatusUpdateForm } from '../../../app/customFormSlice';
import styles from './characteristicCustomForm.module.scss'
import editIcon from '../../../static/images/edit-icon-blue.png'
import { EDIT, CANCEL_ID, CANCEL_BTN, SUBMIT_ID, SAVE_BTN } from '../../../constants'
import { useForm } from 'react-hook-form'
import { Form, TextField, Button, MultiSelect } from '../../primary'
import { useParams } from 'react-router-dom';
import { signLockAsync } from '../../../app/patientSlice';
import { CharacteristicCustomFormSingleSelect } from './characteristicCustomFormSingleSelect';
import { CharacteristicCustomFormMultiSelect } from './characteristicCustomFormMultiSelect';
import { CharacteristicCustomFormText } from './characteristicCustomFormText';
import { CloseWarning } from '../../secondary';
import { signedLockedAccess } from '../../../utils';
import { Confirmation } from '../../secondary/Confirmation/confirmation'   
import warning from '../../../static/images/icon-check-danger.png';
export function CharacteristicCustomForm({ index, name, formId, queryParam, updateDone, selectedIndex, hideIt, assessmentDetails,isCompleted, characteristicTabsData }) {
      // Edit state
  const [editable, setEditable] = useState(false)
  const [formChangesConfirm, setFormChangesConfirm] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [clickCancel, setClickCancel] = useState(false)
  const confirmationClose = () => {
      setConfirmationModal(false);
      setClickCancel(false)
  }
    const yesConfirmationModalClick = () => {
        handleYes()
      setConfirmationModal(false)
      setEditable(false)
      setFormChangesConfirm(false)
      setClickCancel(false)
    }
    useEffect(() => {
        if(editable){
            if(formChangesConfirm&&clickCancel){
                setConfirmationModal(true);
              }else if(clickCancel&&formChangesConfirm===false){
                setClickCancel(false)
                setConfirmationModal(false);
                setEditable(false)
              }
        }
    }, [formChangesConfirm,clickCancel,editable])

    const getUserRole = useSelector(state => state.user);
    const payload = {
        id: formId,
        queryParam: queryParam
    }

    const { register, handleSubmit, errors, reset } = useForm({
        mode: 'onBlur'
    });

    const dispatch = useDispatch();

    // get forms
    let forms = useSelector(state => state.forms.formDetailsData)
    let formBuilder = forms.formBuilderInfo && [...forms.formBuilderInfo]
    forms.formBuilderInfo && formBuilder.sort((a, b) => {
        return a.order - b.order;
    })

    let viewForm = useSelector(state => state.forms.viewForms)
    let showViewForm = useSelector(state => state.forms.viewForms)
    let [getForm, setgetForm] = useState(viewForm)
    let [showForm, setshowForm] = useState(showViewForm)
    let [customFormBuilder, setcustomFormBuilder] = useState(formBuilder)
    const [editformBranch, seteditformBranch] = useState(false)

    useEffect(() => {
        dispatch(setStatusUpdateForm({}))
        reset({})
        formBuilder && setcustomFormBuilder(formBuilder)
        if (showViewForm?.formdata?.length === 0) {
            formBuilder && questionLevelBranch(formBuilder);
        }
    }, [forms])

    useEffect(() => {
        reset({})
        let _viewForm = formBuilder?.length > 0 && viewForm?.formdata?.map((item, index) => {
            let getQueOption = formBuilder?.find((f) => f.id === item.id)
            let order = getQueOption && getQueOption.order || index;
            return { ...item, order }
        }).sort((a, b) => {
            return a.order - b.order;
        })
        _viewForm && setgetForm({ formdata: _viewForm })
        showViewForm && setshowForm(showViewForm)
    }, [viewForm])

    useEffect(() => {
        dispatch(setviewForms({}))
        dispatch(getFormViewAsyn(payload))
        dispatch(formDetailsAsync(formId))
    }, [formId])


    useEffect(() => {
        if (showForm && showForm?.formdata && showForm?.formdata?.length > 0) {
            setEditable(false)
        } else {
            setEditable(true)
        }
    }, [showForm])

    // question && option level branch
    const questionLevelBranch = (formBuilder) => {
        let branchNumber = ""
        let branchFormBuilder = [...formBuilder]
        let branch = [];
        branchFormBuilder.map((value, index) => {//NOSONAR
            if (branchNumber === "" || branchNumber == index || branchNumber === "next") {
                branchNumber = checkBranch(value, index)
                value.type !== "text" &&
                    value.options.map((val, ind) => {
                        if (branchNumber === "") {
                            if (val.goto !== null && val.goto !== undefined) {
                                branchNumber = index
                            }
                        }
                    })
                branch = [...branch, value]
                return 
            }
        })//NOSONAR
        setcustomFormBuilder(branch)
    }

    const checkBranch = (value, index) => {
        let branchNumber = value.goto !== "" && value.goto !== null && value.goto !== undefined ? value.goto == "eof" || value.goto == "next" ? value.goto : value.goto - 1 : ""
        return branchNumber
    }

    const optionLevelbranching = (selectedVal, pIndex, id, customFormBuild, branchOptionNum) => {
        let branchNumber = ""
        let branchFormBuilder = [...formBuilder]
        let selectedIndex = branchFormBuilder?.findIndex(x => x.id === id);
        let customForm = [...customFormBuild].slice(0, pIndex + 1)
        let branch = [...customForm];
        branchFormBuilder.map((value, index) => {//NOSONAR
            if (branchNumber === "" || branchNumber == index || branchNumber === "next") {
                if (selectedIndex <= index) {
                    if (id !== value.id) {
                        branchNumber = checkBranch(value, index)
                        if (value?.options != null && value?.options?.length > 0) {
                            value.options.map((opt, optind) => {
                                if (branchNumber === "") {
                                    if (opt.goto !== null && opt.goto !== undefined) {
                                        branchNumber = index
                                    }
                                }
                            });
                        }
                        branch = [...branch, value]
                    } else {
                        branchNumber = branchOptionNum
                    }
                }
                return 
            }
        })//NOSONAR
        setcustomFormBuilder(branch)
        seteditformBranch(true)
    }

    let upDateStatus = useSelector(state => state.forms.upDateSaveForm)
    const onSubmit = () => {
        let saveFormData = formBuilder?.length > 0 && getForm?.formdata?.map((item, index) => {
            let getQueOption = formBuilder?.find((f) => f.id === item.id)
            if (getQueOption)
                return item
        })
        if (showViewForm.formdata !== null && showViewForm.formdata !== undefined && showViewForm.formdata.length > 0) {
            payload['method'] = "PUT"
        } else {
            payload['method'] = "POST"
        }
        payload['data'] = { formdata: saveFormData }
        let queryParamList = { feature: "NE1 WMS", subFeature: "Characteristics", ne1Id: queryParam?.ne1Id, assessmentId: queryParam?.assessmentId }
        dispatch(updateViewFormAsyn(payload, queryParamList))
        setTimeout(() => {
            updateDone(index)
            selectedIndex(index + 1)
        }, 1500)
    }

    // Call sign and lock on saving the form
    const { patientId, assessmentId } = useParams();
    useEffect(() => {
        if (upDateStatus.status === 'success') {
            const signLockPayload = {
                patientId,
                assessmentId,
                type: 'signlockactions'
            }
            dispatch(signLockAsync(signLockPayload))
        }
    }, [upDateStatus])

    /* Function to toggle edit */
    const handleEdit = () => {
        setEditable(true)
    }

    const handleCancel = () => {
        //setCloseWarning(true)
        setClickCancel(true)
    }

    const [closeWarning, setCloseWarning] = useState(false)
    const handleYes = () => {
        setgetForm(viewForm)
        setCloseWarning(false)
        setEditable(false)
    }
    /* No fn */
    const handleNo = () => {
        setCloseWarning(false)
    }

    // questions
    const questionType = (data, index) => {
        let optionData = []
        data.type !== "text" && data.options.map((optionValue, optionIndex) => {
            return optionData = [...optionData, { key: optionValue.key, value: (optionValue.key === "other") ? 'Others' : optionValue.value }]
        })
        return questionTypeInput(data, index, optionData)
    }

    // question type
    const questionTypeInput = (data, index, optionData) => {
        let type = {
            "singleselect": <CharacteristicCustomFormSingleSelect
                data={data}
                index={index}
                optionData={optionData}
                getForm={getForm}
                showViewForm={showViewForm}
                register={register()}
                singleSelectVal={SelectedVal}
                optionLevelbranching={optionLevelbranching}
                customFormBuilder={customFormBuilder}
                checkBranch={checkBranch}
            />,
            "multiselect": <CharacteristicCustomFormMultiSelect
                data={data}
                index={index}
                optionData={optionData}
                getForm={getForm}
                showViewForm={showViewForm}
                register={register()}
                addItemMultiselect={SelectedVal}
                removeItemMultiSelect={SelectedVal}
                optionLevelbranching={optionLevelbranching}
                customFormBuilder={customFormBuilder}
                checkBranch={checkBranch}
            />,
            "text": <CharacteristicCustomFormText
                data={data}
                index={index}
                getTextBoxVal={SelectedVal}
                getForm={getForm}
                showViewForm={showViewForm}
                register={register()}
            />
        }
        return type[data.type]
    }

    // updatevalue
    const SelectedVal = (dataform) => {
        setgetForm(dataform)
    }


    // edit form
    const EditForm = () => {
        let editForm = customFormBuilder && [...customFormBuilder]
        if (showViewForm?.formdata?.length > 0) {
            let viewFormData = { ...getForm };
            let formList = customFormBuilder && [...customFormBuilder]
            let viewForm = viewFormData.formdata && [...viewFormData.formdata]
            let data = viewForm && viewForm?.map((items, index) => {
                let getQueOption = formList?.find((f) => f.id === items.id)
                if (getQueOption) {
                    return getQueOption
                }
            })
            editForm = editformBranch ? customFormBuilder && [...customFormBuilder] : data
        }
        return <>
            {closeWarning &&
                <div className="text-center">
                    <CloseWarning
                        message="All progress in this section will be lost. Are you sure want to cancel?"
                        yesFn={handleYes}
                        noFn={handleNo}
                    />
                </div>
            }
            {getUserRole?.role==='ExternalStandardUser'?
                <><p className={'mt-1'}>Custom form is not selected for this wound</p></>
                :
                Array.isArray(characteristicTabsData)&&characteristicTabsData[0]['category']==="History/Cause"&&characteristicTabsData[0]['isCompleted']===0?<>
                
                <div className={`${styles.errorTissueNull} col-11 text-danger text-center`}>
                                                   {'Please select History/Cause before selecting'} {name}
                                               </div>  
                
                </>
                
                : Array.isArray(characteristicTabsData)&&characteristicTabsData[1]['category']==="Tissue Type"&&characteristicTabsData[1]['isCompleted']===0?<>
 
                <div className={`${styles.errorTissueNull} col-11 text-danger text-center`}>
                                                   {'Please select Tissue Type(s) before selecting'} {name}
                                               </div>  
                
                </>
                :
            <Form setFormChangesConfirm={setFormChangesConfirm} formChanges={true} className={`pl-2 ${styles.patient_content} submit`} onSubmit={handleSubmit(onSubmit)} >
                <div className="col-sm-12">
                    {
                        editForm?.map((value, index) =>
                            <div key={index}>
                                {value && questionType(value, index)}
                            </div>
                        )
                    }
                    {customFormBuilder?.length > 0 ? 
                        hideIt  && signedLockedAccess(assessmentDetails?.assessmentStage) && <div className="text-center pb-4">
                            <br />
                            {
                                       isCompleted===0? 
                                       <Button
                                       //classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                                       classes={{ className: `btn pl-1 mr-4 ${styles.btn_primary}` }}
                                       type="submit"
                                       id={SUBMIT_ID}
                                   >
                                       {'Next'}
                                   </Button>
                                       :<>
                                          {/* Save Button */}
                            <Button
                                //classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                                classes={{ className: `btn pl-1 mr-4 ${styles.btn_primary}` }}
                                type="submit"
                                id={SUBMIT_ID}
                            >
                                {SAVE_BTN}
                            </Button>
                            {/* Cancel button  */}
                            <Button
                             type={'button'}
                               // classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel} handlecancel` }}
                               classes={{ className: `btn ml-2 ${styles.btn_scondary} handlecancel` }}
                                id={CANCEL_ID}
                                onClick={() => {
                                    //window.scrollTo(0, 0);
                                    handleCancel()
                                }}
                            >
                                {CANCEL_BTN}
                            </Button>
                                       </>
                                       }
                         


                        </div> 
                        : 
                        <h4 className="text-center">No form</h4>}
                </div>
            </Form>
            }
        </>
    }

    const ViewForm = () => {
        let viewFormData = { ...getForm };
        let formList = customFormBuilder && [...customFormBuilder]
        let viewForm = viewFormData.formdata && [...viewFormData.formdata]
        return viewForm && viewForm.map((items, index) => {
            let getQueOption = formList?.find((f) => f.id === items.id)
            if (getQueOption) {
                return <div className={`col-12 ${styles.col_spec}`} key={index}>
                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{getQueOption.desc}</label>
                    <div className="col-5 p-0">
                        {
                            showViewForm?.formdata?.length > 0 ? items?.options && items?.options?.length > 0 && items.options.includes('noneoftheabove') ?
                                <div className={`col-form-label ${styles.content_spec}`} key={index}>
                                    None of the above
                                </div>
                                : items.options && items?.options?.length > 0 && items.options.includes('alloftheabove') ?
                                    getQueOption.options.map((allVal, allInd) => {
                                        return allVal.key&& allVal.key !== "alloftheabove" && allVal.key !== "noneoftheabove" &&
                                            <div className={`col-form-label ${styles.content_spec}`}>
                                                {allVal.key !== "other" ? allVal.value : items.value}
                                            </div>
                                    })
                                    : items.options && items?.options?.length > 0 ? items?.options?.map((optVal, optInd) => {
                                        if (optVal !== "") {
                                            let optionData = getQueOption?.options?.find((f) => f?.key&&f.key === optVal)
                                            return <div className={`col-form-label ${styles.content_spec}`} key={optInd}>
                                                {optionData?.key&&optionData.key !== "other" ? optionData.value : items.value}
                                            </div>
                                        }
                                    })
                                        : items.value
                            : ''
                        }
                    </div>
                </div>
            }
        })
    }

    // editicon
    const EditIcon = () => {
        return (
            <div className="">
                {/* {(upDateStatus) && upDateStatus.status === "success" && <div className={`alert alert-success ${styles.form_alert}`}>Successfully updated</div>}
                {(upDateStatus) && upDateStatus.status === "error" && <div className={`alert alert-danger ${styles.form_alert}`}>Form not update</div>} */}
                <h2 className={`${styles.tab_content_hdg}`}>
                    {!editable && 
                        <span className={`float-sm-right ${styles.tab_edit} handleEdit`} onClick={handleEdit} >
                            <img src={editIcon} alt="edit" />{EDIT}
                        </span>
                    }
                </h2>
            </div>
        )
    }

    return (
        <>
   
   {
      confirmationModal && <Confirmation
        onClose={() => confirmationClose()}
        title={" "}
      >
        <div className="text-center pr-5 pl-5">
          <p>
            <img src={warning} alt="warnning-icon" />
          </p>
          <p className="pb-3">All progress in this section will be lost. Are you sure want to cancel?</p>
          <div className="mb-2">
            <Button
              value="Yes"
              classes={{ className: `btn mr-2 pb-2 pt-2 mr-3 ${styles.yes_btn}` }}
              onClick={() => yesConfirmationModalClick()}
            />
            <Button
              id="closeId"
              value="No"
              classes={{ className: `btn ml-2 pb-2 pt-2 ${styles.no_btn}` }}
              onClick={() => confirmationClose()}
            />
          </div>
        </div>
      </Confirmation>
    }

            {signedLockedAccess(assessmentDetails?.assessmentStage) && hideIt && <EditIcon />}
            <div className={`col-md-12 ${styles.single_product_block}`}>
                <div className="row">
                    <div className={`p-0 ${styles.patient_content}`}>
                        {(!editable) && ViewForm()}
                        {(editable) && <EditForm />}
                    </div>
                </div>
            </div>
        </>
    )
}
