import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import style from '../../core/Menu/menu.module.scss';
import DownArrowgi from '../../../static/images/down_arrowgi.gif';
export function Form({ children, className, onSubmit , formChanges=false,directChanges=false, setFormChangesConfirm}) {
  const scrollToRef = useRef(null);

  const [show, setHandleShow] = useState(false);
    const handleChange = (data) => {
      if(setFormChangesConfirm){
        setFormChangesConfirm(true)
      }
    if(formChanges&&!document.getElementById('modal_id')?.classList?.contains('opencheck')){
      setHandleShow(true)
    }
    }
    const scrollToSection = () => {
      /* Scrolling to View re commit*/
      if (scrollToRef.current) {
        scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };
    
    useEffect(() => {
      if(show||directChanges){
       toast.success(<><span style={{ textDecoration: 'underline' }}>Scroll down</span> and click save or next</>, {
         //icon: `${DownArrowgi}`,
         icon: ({theme, type}) =>  <img className={`${style.down_arrowgif}`} src={`${DownArrowgi}`}/>,
         //icon: false,
         containerId: 'warning',
         toastId: 'success1',
         position: "bottom-right",
         autoClose: false,
         hideProgressBar: true,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: 0,
         className: `${style.toast_body}`,
         style: { background: '#36b37e',color:'#ffffff', opacity: '0 !important',fontSize: '15px' },
         onClick: () => scrollToSection(), // Scroll on click
        // pauseOnFocusLoss: false
         }); 
      }
     }, [show,directChanges])

  return (<form data-testid={"formId"} className={className} onSubmit={onSubmit} onChange={handleChange} onKeyPress={(e)=>{e.key === 'Enter' && e.preventDefault();}} autoComplete="nope">
          {children}
           {/* Target section to scroll to */}
      <div ref={scrollToRef}>
      </div>
        </form>)
}